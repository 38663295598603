<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Analitica de Precios</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <oprecom-accordion-comp-group nombre="Productos" :lista="lista" :first="first"/>
      </div>
    </div>
  </template>
  
  <script>
  import OprecomAccordionCompGroup from "@/components/OprecomAccordionCompGroup.vue";
  export default {
    name: "analistica-comp-table",
    props: ['lista','codigo_osinerg','first'],
    data() {
        return {
            productos: [],
        };
    },
    methods: {
    },
    watch: {/* 
        lista : function(newVal) {
            newVal.forEach((item,index) => {
                if(index == 0)
                    this.productos['cod_' + item.cod_producto] = true;
                else
                    this.productos['cod_' + item.cod_producto] = false;
            });
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }*/
    },
    components: {
      OprecomAccordionCompGroup,
    },
  };
  </script>
  