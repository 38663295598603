<template>
  <div class="mb-4 card">
    <div class="p-3 card-body">
      <router-link
        :to="link"
        scoped="div"
        class="d-flex"
        :class="directionReverse ? reverseDirection : ''"
      >
        <div>
          <div
            class="text-center icon icon-shape"
            :class="`${iconBackground} ${
              this.$store.state.isRTL ? 'border-radius-md' : 'border-radius-2xl'
            }`"
          >
            <!-- <i
              class="text-lg opacity-10"
              :class="iconClass"
              aria-hidden="true"
            ></i> -->
            <img
              :src="`./../images/${img}`"
              alt=""
              height="48"
              width="48"
              class="img-circle"
            />
          </div>
        </div>
        <div :class="contentClass">
          <div class="numbers">
            <p
              class="mb-0 text-sm text-uppercase font-weight-bold"
              :class="titleColor"
            >
              {{ title }}
            </p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "screen-button",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    directionReverse: Boolean,
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
    img: {
      type: String,
    },
  },
};
</script>
