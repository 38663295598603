<template>
  <div class="form-check">
    <input class="form-check-input" type="radio" :name="name" :id="id" :value="value" :checked="checked" />
    <label class="label-agente" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "oprecom-radio",
  props: {
    name: String,
    id: String,
    value: String,
    checked: String,
  },
};
</script>
