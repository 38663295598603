<template>
    <div class="accordion-header" :id="`heading${ itemID }}`">
    	<hr />
        <h5 class="mb-0">
            <button 
                @click="toggleAccordion()" 
                class="btn btn-link w-100 text-primary text-left accordion-button" 
                :class="{
                    '' : isOpen,
                    'collapsed' : !isOpen,
                }"
                type="button" 
                data-bs-toggle="collapse" 
                :data-bs-target="`#collapse${ itemID }`" 
                :aria-expanded="isOpen" 
                :aria-controls="`collapse${ itemID }`">
                <slot name="title" />
            <i class="ni float-right" 
            :class="{
                'ni-bold-down': !isOpen,
                'ni-bold-up': isOpen,
            }"></i>
            </button>
        </h5>
    </div>
    <div :id="`collapse${ itemID }`" class="accordion-collapse collapse" :class="{ 'show' : isOpen, '': !isOpen, }" :aria-labelledby="`heading${ itemID }`" :data-bs-parent="`#accordion${nombre}`">
        <div class="accordion-body opacity-8">
            <slot name="content" />
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "oprecom-accordion-item",
    props: {
        itemID: {
            type: String,
            default: '-0',
        },
        nombre: {
            type: String,
            default: '-oprecom',
        }
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
    },
  };
  </script>