<template>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" :name="name" :id="id" :value="value" :checked="checked" />
    <label :for="id" class="label-agente">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "oprecom-checkbox",
  props: {
    name: String,
    id: String,
    value: String,
    checked: String,
  },
};
</script>
