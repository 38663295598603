import * as Vue from "vue";
import App from './App.vue';
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/mapa.css";
import Oprecom from "./oprecom";

// Vue Gooble Maps
import VueGoogleMaps from '@fawmi/vue-google-maps'

// Vue Keycloak
import vueKeycloak from '@dsb-norge/vue-keycloak-js'

// Vue axios
import axios from 'axios';
import VueAxios from 'vue-axios'


const url_logout = process.env.NODE_ENV == 'production' ? 'https://oprecom.peruenergyadvisors.com.pe' : 'http://localhost:8085'
const init_onLoad = process.env.NODE_ENV == 'production' ? 'login-required' : 'check-sso'
const init_config = {
    url: 'https://security.peruenergyadvisors.com.pe',
    realm: 'oprecom',
    clientId: 'oprecom-extranet',
};

function tokenInterceptor (keycloak) {
    axios.interceptors.request.use(config => {
        if (keycloak.authenticated) {
            config.headers.Authorization = `Bearer ${keycloak.token}`
        }
        return config
    }, error => {
        return Promise.reject(error)
    });
}

const appInstance = Vue.createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Oprecom);
appInstance.use(VueAxios, axios);
appInstance.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCmGLwT1u5dy0ccsHJ4AdGMM7izb9S05Pk'
	}
});

appInstance.use(vueKeycloak, {
    init: {
        onLoad: init_onLoad,
    },
    config: init_config,
    logout: {
        redirectUri: url_logout
    },
    onReady: (keycloak) => {
        keycloak.loadUserProfile();
        keycloak.loadUserInfo();
        setTimeout(() => {
            tokenInterceptor (keycloak);
            /* eslint-disable no-new */
            appInstance.mount("#app");
        },150);
    }
});