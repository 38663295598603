<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">          
          <div class="col-lg-3 col-md-6 col-12">
            <screen-button
            title="Analítica de Precios / Competencia"
              link="/analitica/competencia"
              iconClass="fa fa-bell"
              iconBackground="bg-gradient-primary"
              directionReverse
              img="analiticaComp.jpg"
            ></screen-button>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <screen-button
              title="Analítica de Precios"
              link="/analitica/tabular"
              iconClass="fa fa-bell"
              iconBackground="bg-gradient-primary"
              directionReverse
              img="analitica.jpg"
            ></screen-button>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <screen-button
              title="Mapa de Precios"
              link="/analitica/mapa"
              iconClass="fa fa-bell"
              iconBackground="bg-gradient-danger"
              directionReverse
              img="mapa.jpeg"
            ></screen-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ScreenButton from "@/oprecom/Button/ScreenButton.vue";
export default {
  name: "inicio",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  components: {
    ScreenButton,
  },
};
</script>
