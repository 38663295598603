<template>
    <transition name="modal-animation">
        <div v-show="isOpen" class="row mt-4 modal">
            <transition name="modal-animation-inner">
                <div v-show="isOpen" class="col-lg-12 mb-lg modal-container">
                    <div class="modal-header">
                        <slot name="header">
                            
                        </slot>
                        <i @click="close" class="far fa-times-circle"></i>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            <div class="card z-index-2">
                                <OprecomLineChart 
                                    ref="lineChart"
                                    :data="chartData" 
                                    :options="chartOptions"
                                />
                            </div>
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="modal-close-button" @click="close" type="button">Cerrar</button>
                        </slot>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
    import OprecomLineChart from './OprecomLineChart.vue';

    export default {
        name: "oprecom-modal-chart",
        props: {
            isOpen: Boolean,
            chartData: {
                type: Object,
                required: true
            },
            chartOptions: {
                type: Object,
                default: () => {}
            },
        },
        setup(props, { emit }){
            const close = () => {
                emit("close");
            }
            return { close };
        },
        components: { OprecomLineChart },
    }
</script>

<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
    transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
    opacity: 0;
}

.modal-animation-inner-enter-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
    opacity: 0;
    transform: scale(0.8);
}

.modal-animation-inner-leave-to {
    transform: scale(0.8);
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(99, 99, 99, 0.534);

    .modal-container {
        position: relative;
        max-width: 80vw;
        margin: 0px auto;
        padding: 20px 30px;
        border-radius: 2px;
        width: 90%;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transition: all 0.3s ease;
        background-color: #fff;

        i {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 20px;
            cursor: pointer;

            &:hover {
                color: rgb(243, 88, 50);
            }
        }
    }

    .modal-footer {
        .modal-close-button {
            float: right;
        }
    }
}
</style>