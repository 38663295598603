<template>
  <div class="accordion my-3" :id="`accordion${nombre}`">
    <oprecom-accordion-item
      v-for="(producto, index) in lista"
      :key="index"
      :nombre="nombre"
      :itemID="producto.cod_producto.toString()"
    >
      <template v-slot:title>
        {{ producto.producto }} :: {{ producto.precio_venta }} Soles /
        {{ producto.unidad }}
      </template>
      <template v-slot:content>
        <div class="row">
          <div class="col-lg-12 mb-lg align-items-center">
            <oprecom-badge size="lg" color="warning"
              >MIN: {{ producto.menor }} SOL / {{ producto.unidad }}
            </oprecom-badge>
            <oprecom-badge size="lg" color="secondary"
              >PROM:{{ producto.promedio }} SOL / {{ producto.unidad }}
            </oprecom-badge>
            <oprecom-badge size="lg" color="success"
              >MAX: {{ producto.mayor }} SOL / {{ producto.unidad }}
            </oprecom-badge>
            <!-- <button
              @click="showMap(producto.cod_producto)"
              class="btn btn-link text-primary text-right"
              type="button"
            >
              Mapa
              <i class="ni ni-map-big"></i>
            </button> -->
            <img
              :src="`./../images/mapa.png`"
              @click="showMap(producto.cod_producto)"
              alt=""
              height="35"
              width="35"
              style="margin-left: 10px; cursor: pointer"
            />
            <img
              :src="`./../images/evolucion.png`"
              @click="showChart(producto)"
              alt=""
              height="35"
              width="53"
              style="margin-left: 10px; cursor: pointer"
            />
            <img
              :src="`./../images/resumen.jpg`"
              @click="showAbstract(producto)"
              alt=""
              height="35"
              width="35"
              style="margin-left: 10px; cursor: pointer"
            />
          </div>
        </div>
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Competencia
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Fecha
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Soles / {{ producto.unidad }}
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Distancia
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in producto.competencia" :key="index">
                <td :class=item.css>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item.razon_social }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ item.direccion }}
                      </p>
                    </div>
                  </div>
                </td>
                <td :class=item.css>
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{
                      new Date(item.fecha_proceso).toLocaleDateString("en-GB")
                    }}
                    {{
                      new Date(item.fecha_proceso).toLocaleTimeString("en-GB")
                    }}</span
                  >
                </td>
                <td class="align-middle text-center" :class=item.css>
                  <span
                    v-if="!item.editable"
                    :class="item.textStyle"
                    >{{ item.precio_venta }}</span
                  >
                  <table v-else style="margin: auto;">
                    <tr>
                      <td>
                        <oprecom-input
                          type="number"
                          :id="'precio-' + item.cod_producto"
                          :name="'precio-' + item.cod_producto"
                          :value="item.precio_venta"
                          style="width: 100px"
                          size="sm"
                        ></oprecom-input>
                      </td>
                      <td>
                        <i class="fa fa-check cursor-pointer" @click="confirmEdition(item)" title="Confirmar actualización del precio"></i>
                        &nbsp;
                        <i class="fa fa-close cursor-pointer" @click="exitEdition(item)" title="Cancelar"></i>
                      </td>
                    </tr>
                  </table>
                  &nbsp;
                  <i v-if="!item.editable && item.distancia == 0" class="fa fa-pencil cursor-pointer" @click="makeEditable(item)" title="Actualizar precio en la PVO (SCOP)"></i>
                </td>
                <td class="align-middle text-center text-sm" :class=item.css>
                  <span class="text-secondary text-xs font-weight-bold">{{
                    (item.distancia / 1000).toFixed(2) + " KM"
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </oprecom-accordion-item>
  </div>
  <oprecom-modal-map 
    @close="toggleModal"
    :codigo_osinerg="codigo_osinerg"
    :isOpen="modalOpen"
    :markers="markers"
    :distance="distance"
    :first="first"
    :center="center"
    :mayor="mayor"
    :menor="menor"
    :radius="radius"
  >
  </oprecom-modal-map>
  <oprecom-modal-chart 
    @close="toggleModalChart"
    :isOpen="modalChartOpen"
    :chartData="chartData"
    :chartOptions="chartOptions"
  >
  </oprecom-modal-chart>
  <oprecom-modal
    @close="toggleModalComp"
    :isOpen="modalCompOpen"    
  >
    <template v-slot:header>
      {{ resumen.producto }}
    </template>
    <template v-slot:body>
      <oprecom-badge size="lg" color="warning"
              >MIN: {{ resumen.min }} SOL / Galón
            </oprecom-badge>
            <oprecom-badge size="lg" color="secondary"
              >PROM:{{ resumen.prom }} SOL / Galón
            </oprecom-badge>
            <oprecom-badge size="lg" color="success"
              >MAX: {{ resumen.max }} SOL / Galón
            </oprecom-badge>
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Competencia
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Precios
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Mi Precio
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Diferencia
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Distancia (Km)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in resumen.competencia" :key="index">
            <td :class=item.css>
              <span class="text-secondary text-xs font-weight-bold">
                {{item.nombre}}
              </span>
            </td>
            <td :class=item.css>
              <span class="text-secondary text-xs font-weight-bold">
                {{item.precio}}
              </span>
            </td>
            <td class="align-middle text-center" :class=item.css>
              <span class="text-secondary text-xs font-weight-bold">
                {{item.miPrecio}}
              </span>
            </td>
            <td class="align-middle text-center text-sm" :class=item.css>
              <span class="text-secondary text-xs font-weight-bold">
                {{item.diferencia}}
              </span>
            </td>
            <td class="align-middle text-center text-sm" :class=item.css>
              <span class="text-secondary text-xs font-weight-bold">
                {{item.distancia}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <div>
        <span class="font-weight-bold txtSugerencia">
          {{"Sugerencia ==> " + resumen.sugerencia}}
        </span>
      </div>
    </template>
  </oprecom-modal>
  </template>
  <!-- collapse show    btn btn-link w-100 text-primary text-left      btn btn-link w-100 text-primary text-left collapsed -->
  <script>
  import { ref } from "vue";
  import OprecomAccordionItem from '@/components/OprecomAccordionItem.vue';
  import OprecomBadge from "@/components/OprecomBadge.vue";
  import OprecomModalMap from "@/components/OprecomModalMap.vue";
  import OprecomModalChart from "@/components/OprecomModalChart.vue";
  import OprecomInput from "@/components/OprecomInput.vue";
  import OprecomModal from "@/components/OprecomModal.vue";

  const url_list_precios_chart = 'https://x302l701ch.execute-api.us-east-2.amazonaws.com/oprecom/showChartCompetencia';
  const url_getVaultValue = 'https://2z4omvpr64.execute-api.us-east-2.amazonaws.com/oprecom/getVaultValue';
  const url_putVaultValue = 'https://7ek2kxg0cl.execute-api.us-east-2.amazonaws.com/default/putVaultValue';
  const url_SavePrecioEnOsinergmin = 'https://kwtq5yweq1.execute-api.us-east-2.amazonaws.com/oprecom/SavePrecioEnOsinergmin';
  // const url_SavePrecioEnOsinergmin = 'https://ms99gp5ko4.execute-api.us-east-2.amazonaws.com/oprecom/SavePrecioEnOsinergmin';
  const url_validUserPwdOnPvo = 'https://d1a7ikqdel.execute-api.us-east-2.amazonaws.com/oprecom/validUserPwdOnPvo';
  const url_showResumenCompetencia = 'https://t4h93x0ss2.execute-api.us-east-2.amazonaws.com/oprecom/showResumenCompetencia';

  export default {
    name: "oprecom-accordion-comp-group",
    props: {
      nombre: {
        type: String,
        default: "-oprecom"
      },
      lista: {
        type: Array,
        default: null
      },
      first: {
        type: Object,
        default: function () { return {} }
      },
    },
    data() {
      return { 
        codigo_osinerg: "",
        modalOpen: ref(false),
        modalChartOpen: ref(false),
        modalCompOpen: ref(false),
        markers: [],
        distance: 500,
        radius: 500,
        center: { lat: -12.0926578, lng: -77.1218662 },
        mayor: 0,
        promedio: 0,
        menor: 0,
        precio: 0,
        resumen: {},
        chartData: {
          labels: ['01/02/2024', '02/02/2024', '03/02/2024', '04/02/2024', '05/02/2024', '06/02/2024', '07/02/2024'],
          datasets: [
            {
              label: 'Data 1',
              backgroundColor: '#f87979',
              borderColor: '#f87979',
              borderWidth: 4,
              pointBorderWidth: 8,
              data: [40, 39, 10, 40, 39, 80, 40]
            },
            {
              label: 'Data 2',
              backgroundColor: '#3DFF33',
              borderColor: '#3DFF33',
              borderWidth: 4,
              pointBorderWidth: 8,
              data: [10, 49, 20, 20, 19, 50, 30]
            }
          ] 
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              position: 'top',
              labels: {
                        // This more specific font property overrides the global property
                        font: {
                            size: 12,
                            weight: 'bold'
                        }
                    }
            },
            title: {
              display: true,
              text: 'Evolución de Precios',
              padding: {
                  top: 5,
                  bottom: 15
              },
              font: {
                  size: 20,
                  weight: 'bold'
              }
            }
          },
            scales: {
                x: {
                    ticks: {
                      font: {
                          size: 12,
                          weight: 'bold'
                      }
                    }
                },
                y: {
                    ticks: {                                            
                      font: {
                          size: 13,
                          weight: 'bold'                      
                      }
                    },
                    title: {
                      display: true,
                      text: "Soles/Galón"
                    }                    
                }
            }
        }
      };
    },
    methods: {
      toggleModal (){
        this.modalOpen = !this.modalOpen
      },
      toggleModalChart (){
        this.modalChartOpen = !this.modalChartOpen
      },
      toggleModalComp (){
        this.modalCompOpen = !this.modalCompOpen
      },
      makeEditable(item){
        item.editable = true;
      },
      confirmEdition(item){
        this.precio = document.getElementById("precio-" + item.cod_producto).value;
        if (this.precio!=""){
          if (!(Number(this.precio) >= 0 && Number(this.precio) <= 99)){
            alert("El valor ingresado no es válido, favor de revisar");
            return;
          }
        }else{
          return;
        }
        //Verificamos si tenemos registrado la clave del código Osinergmin
        console.log(item.codigo_osinerg);
        var pwd = "";
        this.axios.get(url_getVaultValue + '?' + "user="+item.codigo_osinerg).then(response => {
          pwd = response.data;                     
          if (pwd != ""){
            //verificamos si la clave es válida
            this.validarUserPwd(item, pwd);         
          }else{
            //Se debe solicitar ingreso de su clave PVO
            this.ingresarClave(item, "Ingrese su contraseña de acceso a la PVO (SCOP)");
          }       
        }).catch (e => console.log(e));         
      },
      ingresarClave(item, msj){
          let newPwd = prompt(msj);
          if (newPwd!=null && newPwd!=""){
            if (confirm("¿Desea guardar su contraseña para futuros registros de precios?")){
              this.saveClaveVault(item, newPwd);
            }else{
              this.validarUserPwd(item, newPwd);
            }
          }else{
            item.editable = false;
          }
      },
      saveClaveVault(item, pwd){
        this.axios.get(url_putVaultValue + '?' + "user="+item.codigo_osinerg + "&pass="+pwd).then(response => {
          var data = response.data;          
          console.log(data);
          this.validarUserPwd(item, pwd);          
        }).catch (e => console.log(e));
      },
      validarUserPwd(item, pwd){
        this.axios.get(url_validUserPwdOnPvo + '?' + "user=" + item.codigo_osinerg +"&pwd="+pwd).then(response => {
              var data = response.data;
              if (data == "SI"){
                if (confirm("¿Esta seguro de registrar el precio ingresado?\nRecuerde que el precio ingresado se registrará en el sistema PRICE del Osinergmin y a su vez en el Facilito")){
                  this.savePrecio(item, pwd);
                }                
              }else{
                this.ingresarClave(item, "La contraseña guardada no es válida,\nIngrese nuevamente su contraseña de acceso a la PVO (SCOP)");
              }
            }).catch (
                e => console.log(e)               
              )
      },
      savePrecio(item, pwd){
          // var post = {
          //   codOsi: item.codigo_osinerg,
          //   pwd: pwd,
          //   codProd: item.cod_producto,
          //   prcio: this.precio,
          //   codUnidad: item.unidad
          // };
          this.axios.get(url_SavePrecioEnOsinergmin + '?' + "codOsi="+item.codigo_osinerg+"&pwd="+pwd+"&codProd="+item.cod_producto+"&prcio="+this.precio+"&codUnidad="+item.unidad).then(response => {
          // this.axios.post(url_SavePrecioEnOsinergmin, post).then(response => {
              var data = response.data;
              if (data=='0'){
                item.precio_venta = this.precio;
                alert("El precio fue registrado satisfactoriamente en el sistema PRICE y Facilito del Osinergmin");                
              }else{
                alert("Código "+data+": No se pudo registrar el precio en la PVO de Osinergmin.");
              }
              item.editable = false;
            }).catch (e => console.log(e));
      },
      exitEdition(item){
        console.log(item);
        item.editable = false;
        return;
      },
      showMap(id) {
        var competencia = [];
        for(var i = 0; i < this.lista.length; i++){
          var producto = this.lista[i];
          if(producto.cod_producto == id){
            competencia = producto.competencia;
            break;
          } 
        }
        var nmarkers = [];
        competencia.forEach((item, index) => {
          nmarkers[index] = {
            position: { lat: item.latitud, lng: item.longitud },
            codigo_osinerg: item.codigo_osinerg,
            razon_social: item.razon_social,
            direccion: item.direccion,
            icon: "",
            producto: {
              cod_producto: item.cod_producto,
              producto: item.producto,
              precio_venta: item.precio_venta,
              unidad: item.unidad,
              marca: item.marca,
              fecha_proceso: item.fecha_proceso,
            },
          };
        });

        nmarkers.forEach((item, index) => {
          var precio = parseFloat(item.producto.precio_venta);
          if (index > 0) {
            if (precio > this.mayor) this.mayor = precio;
            if (precio < this.menor) this.menor = precio;
          } else {
            this.mayor = precio;
            this.menor = precio;
          }
        });
        this.center = this.first.position;
        this.markers = nmarkers;
        this.codigo_osinerg = this.first.codigo_osinerg;
        this.radius = Number(this.first.radius);
        
        this.toggleModal();
      },
      showChart(producto) {
        this.axios.get(url_list_precios_chart + '?' + "cod_osinerg=" + this.first.codigo_osinerg + "&cod_producto=" + producto.cod_producto).then(response => {
          var data = response.data;
          console.log(data);
          this.chartData = data;          
          this.getChartOptions(producto);          
        }).catch (e => console.log(e));
        
        this.toggleModalChart();
      },
      getChartOptions(producto){
        this.chartOptions= {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                position: 'top',
                labels: {
                          // This more specific font property overrides the global property
                          font: {
                              size: 12,
                              weight: 'bold'
                          }
                      }
              },
              title: {
                display: true,
                text: 'Evolución de Precios del '+producto.producto,
                padding: {
                    top: 5,
                    bottom: 15
                },
                font: {
                    size: 20,
                    weight: 'bold'
                }
              }
            },
              scales: {
                  x: {
                      ticks: {
                        font: {
                            size: 13,
                            weight: 'bold'
                        }
                      }
                  },
                  y: {
                      ticks: {                                            
                        font: {
                            size: 14,
                            weight: 'bold'                      
                        }
                      },
                      title: {
                        display: true,
                        text: "Soles/"+producto.unidad
                      }                    
                  }
              }
          }
      },
      showAbstract(producto){                
        var codOsi = this.first.codigo_osinerg;
        console.log("codigo_osinerg=" + codOsi + "&cod_producto=" + producto.cod_producto + "&latitud=" + this.first.position.lat + "&longitud=" + this.first.position.lng);
        this.axios.get(url_showResumenCompetencia + '?' + "codigo_osinerg=" + codOsi + "&cod_producto=" + producto.cod_producto + "&latitud=" + this.first.position.lat + "&longitud=" + this.first.position.lng).then(response => {
          var data = response.data;
          this.resumen = data;
        }).catch (e => console.log(e));
        this.toggleModalComp();
      }
    },
    components:{
        OprecomAccordionItem,
        OprecomBadge,
        OprecomModalMap,
        OprecomModalChart,
        OprecomInput,
        OprecomModal,
    },
  };
  </script>

<style lang="scss" scoped>
.bkgUO{
  background-color: #e4e8ed;
}
.bkgUOE{
  background-color: #f4c4df;
}
.txtSugerencia{
  color: #e80f0f !important;
  font-size: 1.2rem;
}
</style>