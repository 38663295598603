<template>
  <div class="form-group">
      <label :for="id" class="label-agente">
        <slot />
      </label>
      <select
        class="form-control"
        :class="getClasses(size, valid)"
        :name="name"
        :id="id"
		:value="value"
        :isRequired="isRequired"
      >
		<option v-for="item in list" :key="item[optionValue]" :value="item[optionValue].toString()">{{ item[optionText] }}</option>
	  </select>
  </div>
</template>

<script>
export default {
  name: "oprecom-select",
  props: {
    size: {
      type: String,
      default: "default",
    },
    valid: {
      type: Boolean,
      default: false,
    },
	list: Array,
    name: String,
    id: String,
    value: {
		type: String,
		default: ""
	},
	optionValue: {
		type: String,
		default: "value"
	},
	optionText: {
		type: String,
		default: "text"
	},
    isRequired: Boolean,
  },
  methods: {
    getClasses: (size, valid) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      isValidValue = valid ? `${valid}` : "invalid";

      return `${sizeValue} ${isValidValue}`;
    },
    getValue: (value) => (value ? `${value}` : null),
  },
};
</script>
