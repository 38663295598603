<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
		Grifos
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "inicio",
  data() {
    return {

    };
  },
  mounted(){
	
  },
  methods: {

  },
  components: {

  },
};
</script>
