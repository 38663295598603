<template>
    <Line 
        id="line-chart"
        :options="options"
        :data="data"
    />
</template>
<script>
  import { Line } from 'vue-chartjs'
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip,  Legend} from 'chart.js'
  
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

  export default {
    name: "oprecom-line-chart",
    components: { Line },
    props: {
      data: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {

      };
    },
  };
</script>