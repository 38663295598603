<template>
  <GMapMap
    :center="center"
    :zoom="zoom"
    map-type-id="terrain"
    ref="gmap"
    :style="`width: 75vw; height: 40rem; -webkit-border-radius: 20px; z-index: 0`"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }"
  >
    <GMapCircle  v-if="radius > 0"
      :options="{
        center: center,
        radius: radius,
        strokeColor: `#FF0000`,
        strokeOpacity: 0.8,
        strokeWeight: 1.5,
        fillColor: `#FF0000`,
        fillOpacity: 0.1,
      }"
    ></GMapCircle>
    <GMapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      @click="showInfo(m.codigo_osinerg)"
      :icon="m.icon"
      :label="{
        text: `S/ ${m.producto.precio_venta}`,
        color: 'black',
        fontSize: '13px',
        fontWeight: '900',
        className: 'labelMark',
      }"
    >
      <GMapInfoWindow
        :closeclick="true"
        @closeclick="showInfo(null)"
        :opened="info === m.codigo_osinerg"
      >
        <div class="property">
          <div class="imgEESS">
            <img class="imgEESS" src="./../../public/images/eess.png" />
          </div>
          <div class="details">
            <div class="price">
              {{ m.producto.producto }} - S/{{ m.producto.precio_venta }}
            </div>
            <div class="uniope">{{ m.razon_social }}</div>
            <div class="address">
              {{ m.direccion }}
            </div>
            <div class="features">
              <div title="Servicio de llantería">
                <img
                  class="imgService"
                  src="./../../public/images/neumatico.png"
                />
              </div>
              <div title="Servicio de autolavado">
                <img
                  class="imgService"
                  src="./../../public/images/autoservicio.png"
                />
              </div>
              <div title="Cajero automático">
                <img
                  class="imgService"
                  src="./../../public/images/cajero.png"
                />
              </div>
              <div title="Minimarket">
                <img
                  class="imgService"
                  src="./../../public/images/carrito.png"
                />
              </div>
            </div>
          </div>
        </div>
      </GMapInfoWindow>
    </GMapMarker>
  </GMapMap>
</template>
<script>
const marker_blue = "./../images/turquesaA.png";
const marker_Orange = "./../images/amarilloA.png";
const marker_red = "./../images/rojoA.png";
const marker_green = "./../images/verdeA.png";

export default {
  name: "oprecom-mapa",
  props: {
    markers: {
      type: Array,
      default: null,
    },
    distance: {
      type: Number,
      default: 500,
    },
    radius: {
      type: Number,
      default: 500,
    },
    first: {
      type: Object,
      default: null,
    },
    center: {
      type: Object,
      default: null,
    },
    mayor: {
      type: Number,
      default: 0,
    },
    menor: {
      type: Number,
      default: 0,
    },
    codigo_osinerg: String,
  },
  data() {
    return {
      zoom: 15,
      info: null,
    };
  },
  watch: {
    markers: function () {
      this.markers.forEach((item) => {
        if (item.codigo_osinerg != this.codigo_osinerg) {
          var precio = parseFloat(item.producto.precio_venta);
          if (precio == this.menor) item.icon = marker_red;
          else if (precio == this.mayor) item.icon = marker_green;
          else item.icon = marker_Orange;
        } else {
          item.icon = marker_blue;
        }
      });
    },
    codigo_osinerg: function (newVal) {
      this.markers.forEach((item) => {
        if (item.codigo_osinerg == newVal) {
          item.icon = marker_blue;
        }
      });
    },
  },
  methods: {
    showInfo(codigo_osinerg) {
      this.info = codigo_osinerg;
    },
  },
};
</script>
