<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <div class="card-header pb-0">
          <h6>Bienvenido</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
           RUC  {{ this.keycloak.preferred_username }} - {{ this.keycloak.family_name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "inicio",
  data() {
    return {
      keycloak_user: {},
      keycloak : {},
      userInfo : {},
      profile :{},
    }
  },
  mounted(){
    this.keycloak_user = this.$keycloak;
    if(this.keycloak_user.authenticated){
      this.keycloak = this.keycloak_user.tokenParsed;
      this.userInfo = this.keycloak_user.keycloak.userInfo;
      this.profile = this.keycloak_user.keycloak.profile;
      console.log(this.keycloak_user)
    }else{
      this.keycloak = {
        preferred_username : 'No User',
        family_name : 'Autenticated'
      };
    }
    
  },
  methods: {

  },
  components: {

  },
};
</script>
