<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
		<div class="row">
			<div class="col-lg-12 mb-lg">
			  <label :for="id" class="label-agente">
				RUC: {{ ruc }} - {{ razon_social }}
			  </label>
			</div>
		</div>
		<div class="row">
          <div class="col-lg-7 mb-lg">
			<oprecom-select 
					id="codigo_osinerg" 
					:list="listGrifos" 
					optionValue="codigo_osinerg" 
					optionText="descripcion" 
					:value="codigo_osinerg"
					@change="changeGrifo($event)"
				>Grifo:
			</oprecom-select>
		  </div>
          <!-- <div class="col-lg-2 mb-lg">
			<oprecom-select 
					id="distance" 
					:list="listDistancia" 
					optionValue="value" 
					optionText="text" 
					:value="distance"
					@change="changeDistancia($event)"
				>Distancia:
			</oprecom-select>
		  </div> -->
		</div>
		<div class="row">
			<div class="col-lg-12 mb-lg">
				<competencia-table :codigo_osinerg="codigo_osinerg" :lista="productos" :first="first" :codigoSel="codSel" />
			</div>
		</div>
      </div>
    </div>
  </div>
</template>
<script>
import OprecomSelect from "@/components/OprecomSelect.vue";
import CompetenciaTable from "./components/CompetenciaTable.vue";

const url_list_grifos = 'https://ve362kitt3.execute-api.us-east-2.amazonaws.com/oprecom/findGrifoPorRucLambda';
const url_list_competencia = 'https://36s3ge7zag.execute-api.us-east-2.amazonaws.com/oprecom/findCompetenciaPorCodOsi';

export default {
  name: "tabular",
  data() {
    return {
		zoom: 15,
		distance: 40000,
		ruc: "",
		codigo_osinerg: '',
		razon_social: '',
		codSel:"",
		productos: [],
		listGrifos: [],
		listProductos: [],
		first: {},
		center: {lat: -12.0926578, lng: -77.1218662},
		listDistancia: [
			{value:500, text:'0.5 KM'},
			{value:1000, text:'1 KM'},
			{value:2000, text:'2 KM'},
			{value:3000, text:'3 KM'},
			{value:4000, text:'4 KM'},
			{value:5000, text:'5 KM'},
			{value:10000, text:'10 KM'}
		],
    };
  },
  mounted(){
    const keycloak_user = this.$keycloak;
    this.ruc = keycloak_user.tokenParsed.preferred_username;
	this.getGrifosList();
  },
  methods: {
	getGrifosList(){
		this.axios.get(url_list_grifos + '?' + "ruc=" + this.ruc).then(response => {
			var data = response.data;
			this.listGrifos = data;
			
			this.first = {
				position: {
				lat: this.listGrifos[0].latitud,
				lng: this.listGrifos[0].longitud,
				},
				codigo_osinerg: this.listGrifos[0].codigo_osinerg,
				razon_social: this.listGrifos[0].razon_social,
				icon: "",
				distance: this.distance,
			};

			this.codigo_osinerg = this.listGrifos[0].codigo_osinerg;
			this.razon_social = this.listGrifos[0].razon_social;
			
			this.getCompetenciaList(this.listGrifos[0]);
		}).catch (e => console.log(e));
	},
	getCompetenciaList(grifo){
		this.axios.get(url_list_competencia + '?' + "cod_osinerg=" + grifo.codigo_osinerg + "&distancia=" + this.distance).then(response => {
			var data = response.data;
			console.log(grifo.codigo_osinerg);			
			var indice = -1;
			data.forEach((item, index) => {
				item.competencia = false;
				item.competenciaEst = false;
				if (item.distancia==0){
					indice = index;
				}
				if (item.tipo_competencia=='P'){
					item.competenciaEst = true;
					this.codSel = item.codigo_osinerg;
				}				
				if (item.tipo_competencia=='S'){
					item.competencia = true;
				}
			});
			if(indice > -1) data.splice(indice, 1);
			console.log(data);
			this.productos = data;

		}).catch (e => console.log(e));
	},
	changeGrifo(event){
		this.listGrifos.forEach((item) => {
			var _codigo_osinerg = item.codigo_osinerg;
			if(event.target.value == _codigo_osinerg){
				this.codigo_osinerg = _codigo_osinerg;
				this.getCompetenciaList(item);
			}			
		});
	},
	changeDistancia(event){
		this.distance = event.target.value;
		this.listGrifos.forEach((item) => {
			var _codigo_osinerg = item.codigo_osinerg;
			console.log("Antes del IF")
			if(this.codigo_osinerg == _codigo_osinerg){
				this.getCompetenciaList(item);
			}
		});
	},
  },
  components: {
	CompetenciaTable,
	OprecomSelect,
  },
};
</script>
